@import '../../variables';

.gallery-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 920px;
  margin: 0 auto;
}

.photo {
  width: 300px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.slider-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.close,
.prev,
.next {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: $color-white;
  z-index: 9999;

  &:hover {
    opacity: 1;
  }
}

.close {
  top: 40px;
  right: 40px;
}

.prev {
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.next {
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.full-screen-image {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}
