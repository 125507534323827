.video {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: calc(100% - 64px);
  object-fit: cover;
  filter: brightness(80%);

  @media (min-width: 768px) {
    height: calc(100% - 84px);
  }

  @media (min-width: 1024px) {
    height: calc(100% - 104px);
  }
}
