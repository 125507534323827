@import '../../variables.scss';

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: $color-white;
  text-align: center;
}
