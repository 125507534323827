@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Teko&display=swap');

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family-base;
  padding: 0;
  margin: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('./assets/logo_words.png') no-repeat center center fixed;
  background-size: contain;
}
