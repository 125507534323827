.elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.hamburger {
  display: none;
}

.active-link {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .elements {
    position: absolute;
    right: 0;
    top: 64px;
    width: 0px;
    height: calc(100vh - 120px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .elements.active {
    width: 270px;
  }

  .elements ul {
    display: flex;
    flex-direction: column;
  }

  .elements ul li {
    margin-right: unset;
    margin-top: 22px;
    white-space: nowrap;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .elements {
    top: 84px;
  }
}
