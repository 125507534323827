@import '../../variables';

.table {
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
    color: $color-white;
    width: 16.67%;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $color-primary;
    color: $color-white;
  }
}
