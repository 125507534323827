.logo {
  max-height: 60px;

  @media (min-width: 768px) {
    max-height: 80px;
  }

  @media (min-width: 1024px) {
    max-height: 100px;
  }
}
